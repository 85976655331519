import domReady from '@roots/sage/client/dom-ready';
import { initializeBlock } from '@scripts/initializeBlock';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

/**
 * Initialize Swiper
 * @param {HTMLElement} block - The block element containing the swiper container.
 */
const initializeSwiper = (block) => {
  // eslint-disable-next-line no-unused-vars
  const swiper = new Swiper(block.querySelector('.swiper'), {
    loop: true,
    slidesPerView: 1.3,
    slidesPerGroup: 1,
    spaceBetween: 4,
    speed: 600,
    navigation: {
      nextEl: block.querySelector('.custom-swiper-button-next'),
      prevEl: block.querySelector('.custom-swiper-button-prev'),
    },
    breakpoints: {
      640: {
        slidesPerView: 1.6,
      },
      1024: {
        slidesPerView: 2.5,
      },
      1280: {
        slidesPerView: 3.5,
      },
    },
  });
};

/**
 * Dom ready
 */
domReady(() => {
  initializeBlock('.wp-block-accommodation-slider', initializeSwiper);
});
